<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex flex-1 overflow-auto flex-col">
      <p class="font-bold text-blue-500 mb-6 text-lg">contact details</p>
      <div class="form-row">
        <div class="form-col">
          <label for="full_name">name</label>
          <p class="text-base">
            {{full_name}}
          </p>
        </div>
        <div class="form-col">
          <label for="email">email address</label>
          <p class="text-base">
            {{email}}
          </p>
        </div>
        <div class="form-col">
          <label for="phone_number">phone number</label>
          <p class="text-base">
            {{phone_number}}
          </p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label for="notes">notes</label>
          <p class="text-base">
            {{notes}}
          </p>
        </div>
      </div>
      <div class="overflow-auto w-full">
        <div
        class="mb-2"
        v-for="(conversation, index) in conversations"
        :key="conversation.conversation_id"
        >
          <vuetable
            ref="vuetable"
            :api-mode="false"
            class="list"
            :fields="[
              {
                name: 'requester',
                title: `conversation ${index}`,
                sortField: 'requester'
              },
              { 
                  name: 'message',
                  title: `language: ${conversation.conversation.conversation_language}`,
                  sortField: 'message'
              },
              {
                name: 'turn_time',
                title: `date ${conversation.conversation.date}`,
                sortField: 'turn_time'
              }
            ]"
            :data="conversation.data"
            :css="css.table"
            track-by="requester"
            :show-sort-icons="false"
          />
      </div>

      </div>
    </div>
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import Loader from '@/components/ui/Loader'
import Vuetable from 'vue3-vuetable';
const css = {
  table: {
    tableClass: 'table-auto w-full',
    tableBodyClass: '',
    tableHeaderClass: 'py-2',
    tableWrapper: '',
    loadingClass: 'loading',
    ascendingIcon: 'blue chevron up icon',
    descendingIcon: 'blue chevron down icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
  }
}


export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
  components: {
    Loader,
    Vuetable
  },
  props: {
  css: {
      type: Object,
      default() {
        return css;
      },
    }
  },
  data() {
    return {
      loading: false,
      full_name: '',
      email: '',
      phone_number: '',
      notes: '',
      conversations: []
    }
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$dhDataProvider
      .get('guest', { id: this.$route.params.id })
      .then((response) => {
        this.full_name = response.fullname,
        this.email =  response.email,
        this.phone_number = response.phone_number,
        this.notes = response.notes
      })
      .catch((err) => this.notifyError(err.message))
      this.$dhDataProvider
        .get('guestConversations', { id: this.$route.params.id, communityId: this.community?.id })
        .then((response) => {
          this.conversations = response
        })
        .catch((err) => this.notifyError(err.message))
        .finally(() => {
          this.loading = false;
        })
    },
  },
  created() {
    this.fetchData();
  }
}
</script>

<style scoped>
:deep(.list) tr > th {
  min-width: 200px;
}
</style>
